import * as React from "react";
import styled from "styled-components";
// import { graphql, useStaticQuery } from "gatsby";
import { SharePost } from "./sharePost";

// styles

const Wrapper = styled.div`
  padding: 1rem 0;
  margin-bottom: 3.5rem;
  /* background-color: #ededed; */
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AuthorAndDateContainer = styled.div`
  /* > em:not(:first-child) {
    margin-right: 1rem;
  } */
  font-size: .85rem;
  color: grey;
  font-family: acumin-pro-wide, sans;

`

const Highlight = styled.em`

  color: #2f2f2f;
  font-style: normal;
  margin: 0 6px;
`

interface PostInfoProps {
  // thumbnail
  authorName: string;
  date: string;
  url: string;
  hashtags: string;
  title: string;
}

export const PostInfo: React.FunctionComponent<PostInfoProps> = ({
  authorName,
  date,
  url,
  hashtags,
  title
}) => {
  return (
    <Wrapper>
      <Container>
        <AuthorAndDateContainer>
          Written by<Highlight>{authorName}.</Highlight>
          Uploaded<Highlight>{date}</Highlight>
        </AuthorAndDateContainer>
        <SharePost title={title} url={url} hashtags={hashtags} />
      </Container>
    </Wrapper>
  );
};

// export const query = graphql`
//   fragment PostInformation on PrismicBlogPostDataBody1PostInformation {
//     primary {
//       date(fromNow: true)
//       author_name {
//         text
//       }
//     }
//   }
// `;
