import * as React from 'react';

interface IFrameProps {
    node: any
}
 
const IFrame: React.FunctionComponent<IFrameProps> = ({ node }) => {
    console.log(node);
    return ( 
    // <iframe  allow="encrypted-media" width="100%" height="100%" src={`${node.oembed.embed_url}&maxwidth=900&maxheight=200`} />
   <div dangerouslySetInnerHTML={{ __html: node.oembed.html}} />

    );
}
 
export default IFrame;