import styled from "styled-components";

export const OL = styled.ol`
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`

export const UL = styled.ul`
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

export const BulletLi = styled.li`
  list-style-type: bullet;
  list-style-position: inside;
  font-family: acumin-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
`;

type LiType = {
  number: any;
}
export const LI = styled.li<LiType>`
  flex: 360px;
  border-radius: 7px;
  background-color: #fafafa;
  list-style-type: decimal;
  list-style-position: inside;
  padding: 1rem;
  font-family: acumin-pro, sans-serif;
  font-weight: 400;
  box-shadow: 1px 2px 3px lightgrey;
  font-style: normal;
  font-size: 1rem;
  color: #1d1d1d;
  height: 150px;
  ::marker {
    color: #000;
    font-weight: 600;
  }
`