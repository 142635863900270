import * as React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import Layout from "../components/layouts/layout";
import SEO from "../components/seo";
import { LanguageSwitcher } from "../components/languageSwitcher";
import { components } from "../slices/blogPost";
import { PostInfo } from "../slices/blogPost/postInfo";
import { RelatedContent } from "../slices/blogPost/relatedContent";
import { SharePost } from "../slices/blogPost/sharePost";
import { P } from "../components/atoms/paragraph";
import { H1, H2 } from "../components/atoms/headings";
import { ExternalBlogPostLink } from "../components/atoms/links";
import { BulletLi, UL } from "../components/atoms/lists";
import IFrame from "../components/atoms/iFrame";

// Blog

// styles

const Wrapper = styled.div`
  max-width: 90ch;
  font-size: 1.05rem;
  margin: var(--top-margin) auto 0 auto;
  padding: 1rem;
  background-color: #fafafa;
  border-radius: 15px;
  box-shadow: 0 1px 3px solid grey;
  @media (min-width: 1280px) {
    padding: 5rem;
  }
`;

const RichTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const BlogPost = ({ data }) => {
  if (!data) return null;
  const doc = data.prismicBlogPost;
  const { lang, type, url } = doc || {};
  const alternateLanguages = doc.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const image = getImage(doc.data.featured_image);

  return (
    <Layout activeDocMeta={activeDoc}>
      <SEO
        lang={doc.lang}
        title={`${doc.data.body.map(
          (ele) => ele.primary.seo_title
        )} | CiteDrive Blogs`}
        description={doc.data.body.map((ele) => ele.primary.meta_description)}
        type="blogPost"
      />
      <Wrapper>
        <GatsbyImage image={image} alt="Test" />

        <PostInfo
          authorName={doc.data.body1[0].primary.author_name.text}
          date={doc.data.body1[0].primary.date}
          url={doc.url}
          hashtags={doc.data.body.map((ele) => ele.primary.hashtags)}
          title={doc.data.body.map((ele) => ele.primary.seo_title)}
        />

        <div
          style={{
            marginBottom: "4rem",
          }}
        >
          <H1>{doc.data.post_title.text}</H1>
          <P style={{ fontWeight: "600" }}>{doc.data.post_subtitle.text}</P>
        </div>

        <RichTextContainer>
          <PrismicRichText
            field={doc.data.body_text.richText}
            components={{
              heading1: ({ children, key }) => (
                <H1 key={key} style={{ margin: "0" }}>
                  {children}
                </H1>
              ),
              heading2: ({ children, key }) => (
                <H2 key={key} style={{ margin: "0" }}>
                  {children}
                </H2>
              ),
              paragraph: ({ children, key }) => (
                <P key={key} style={{ margin: "0" }}>
                  {children}
                </P>
              ),
              list: ({ children, key }) => <UL key={key}>{children}</UL>,
              listItem: ({ children, key }) => (
                <BulletLi key={key}>{children}</BulletLi>
              ),
              hyperlink: ({ node, children, key }) => (
                <ExternalBlogPostLink
                  key={key}
                  children={children}
                  node={node}
                />
              ),
              embed: ({ node, key }) => <IFrame key={key} node={node} />,
            }}
          />
        </RichTextContainer>

        <div style={{ marginTop: "2rem" }}>
          <SharePost
            url={doc.url}
            hashtags={doc.data.body.map((ele) => ele.primary.hashtags)}
            title={doc.data.body.map((ele) => ele.primary.seo_title)}
          />
        </div>
      </Wrapper>
      <RelatedContent data={doc.data.body1} />
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPost($id: String, $lang: String) {
    prismicBlogPost(id: { eq: $id }, lang: { eq: $lang }) {
      id
      lang
      url
      tags
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        body {
          ... on PrismicBlogPostDataBodyMetaTags {
            id
            slice_type
            primary {
              seo_title
              meta_description
              hashtags
            }
          }
        }
        # body1 {
        #   ... on PrismicSliceType {
        #     slice_type
        #   }
        #   ...PostInformation
        #   ...RelatedContent

        # }
        body1 {
          ... on PrismicBlogPostDataBody1PostInformation {
            id
            primary {
              author_name {
                text
              }
              date(fromNow: true)
            }
          }
          ... on PrismicBlogPostDataBody1RelatedContent {
            id
            primary {
              title {
                text
              }
            }
            items {
              more_posts {
                url
                document {
                  ... on PrismicBlogPost {
                    id
                    url
                    data {
                      featured_image {
                        gatsbyImageData
                      }
                      post_title {
                        text
                      }
                      post_subtitle {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
        post_title {
          richText
          text
        }
        post_subtitle {
          richText
          text
        }
        body_text {
          richText
        }
        featured_image {
          gatsbyImageData(width: 1000)
        }
      }
    }
  }
`;
