import * as React from "react";
import ShareOnTwitter from "../../particles/features/share/shareOnTwitter";
// import { graphql } from "gatsby";

interface SharePostProps {
  url: string;
  hashtags?: string;
  title: string;
}

export const SharePost = ({ url, hashtags, title }) => {
  return (
  <>
  <ShareOnTwitter title={title} url={url} hashtags={hashtags} />
  </>);
};
