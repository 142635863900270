import * as React from "react";
import styled from "styled-components";
import TwitterIcon from "../../../assets/twitter-icon";

const Button = styled.button`
  cursor: pointer;
  padding: .25rem;
  border: none;
  width: 36px;
  height: 36px;
  background: inherit;
  border-radius: 3px;
  :hover {
    background: lightgrey;
  }
`

interface ShareOnTwitterProps {
  url: string;
  hashtags: string;
  title: string;
}

const ShareOnTwitter: React.FunctionComponent<ShareOnTwitterProps> = ({
  url,
  hashtags,
  title,
}) => {
  const text = encodeURIComponent(title);
  const blogUrl = `https://citedrive.com${url}`;
  const user_id = "citedrive";
  const hash_tags = hashtags;
  const params = "menubar=no,toolbar=no,status=no,width=570,height=570"; // for window

  function ShareToTwitter() {
    let Shareurl = `https://twitter.com/intent/tweet?url=${blogUrl}&text=${text}&via=${user_id}&hashtags=${hash_tags}`;
    window.open(Shareurl, "NewWindow", params);
  }
  console.log(hashtags);
  return (
    <>
      <Button onClick={() => ShareToTwitter()}><TwitterIcon /></Button>
    </>
  );
};

export default ShareOnTwitter;
