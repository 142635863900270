import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { PrismicLink } from "@prismicio/react";
import styled from "styled-components";
import { H2, H3 } from "../../components/atoms/headings";
import { BlogSubTitle } from "../../components/atoms/paragraph";

// styles

const Wrapper = styled.div`

  max-width: 1280px;
  margin: 5rem auto;
`;

const Container = styled.div`
  display: flex;
  gap: 1rem;
`;

const Card = styled.div`
  border-radius: 8px;
  flex-basis: 300px;
  display; flex;
  flex-direction: column;
  background-color: #fafafa;
  padding: 1rem;
`;

const CardTextContainer = styled.div`
  padding: 1rem;
`

const CardSubTitle = styled.div``;

interface RelatedContentProps {
  data: any;
}

export const RelatedContent: React.FunctionComponent<RelatedContentProps> = ({
  data,
}) => {
  const relatedContent = data[1].items.map((ele) => ele.more_posts.document);
  console.log(relatedContent);

  return (
    <Wrapper>
      {relatedContent && 
      <>
      <H2 style={{textAlign: "center", marginBottom: "5rem"}}>Related posts</H2>
      <Container>
        {relatedContent && (
          <>
            {relatedContent.map((ele, index) => ele && (
              <Card>
                <PrismicLink href={ele.url} key={index}>
                  <GatsbyImage
                    image={getImage(ele.data.featured_image)}
                    alt="Featured Image"
                  />
                  <CardTextContainer>
                  <H3>{ele.data.post_title.text}</H3>
                  <BlogSubTitle>{ele.data.post_subtitle.text}</BlogSubTitle>
                  </CardTextContainer>
                </PrismicLink>
              </Card>
            ))}
          </>
        )}
      </Container>
      </>
      }
    </Wrapper>
  );
};

// export const query = graphql`
//   fragment RelatedContent on PrismicBlogPostDataBody1RelatedContent {
//     primary {
//       title {
//         text
//       }
//     }
//     items {
//       more_posts {
//         link_type
//         uid
//       }
//     }
//   }
// `;
